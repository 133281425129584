// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-tsx": () => import("./../../../src/pages/api.tsx" /* webpackChunkName: "component---src-pages-api-tsx" */),
  "component---src-pages-blog-archive-tsx": () => import("./../../../src/pages/blog/archive.tsx" /* webpackChunkName: "component---src-pages-blog-archive-tsx" */),
  "component---src-pages-funcionalidades-tsx": () => import("./../../../src/pages/funcionalidades.tsx" /* webpackChunkName: "component---src-pages-funcionalidades-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politica-privacidade-tsx": () => import("./../../../src/pages/politica-privacidade.tsx" /* webpackChunkName: "component---src-pages-politica-privacidade-tsx" */),
  "component---src-pages-seguranca-tsx": () => import("./../../../src/pages/seguranca.tsx" /* webpackChunkName: "component---src-pages-seguranca-tsx" */),
  "component---src-pages-tarifas-tsx": () => import("./../../../src/pages/tarifas.tsx" /* webpackChunkName: "component---src-pages-tarifas-tsx" */),
  "component---src-pages-termos-uso-tsx": () => import("./../../../src/pages/termos-uso.tsx" /* webpackChunkName: "component---src-pages-termos-uso-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

